import React from "react";
import { graphql } from "gatsby";
import Layout from "../../layout/layout.component";
import SEO from "../../partials/seo/seo.component";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import PropTypes from "prop-types";

const AdvertisementDisclosure = (props) => {
    const schema = props.pageContext.schema,
        post = props.data.wordpressPage;

    return (
        <Layout>
            <SEO data={schema}/>
            <Container>
                <Row className="justify-content-center">
                    <div className="col-12 col-md-8 col-lg-6 content-width">
                        <h1>{post.title}</h1>
                        <div dangerouslySetInnerHTML={{__html: post.content}}/>
                    </div>
                </Row>
            </Container>
        </Layout>
    );
};

AdvertisementDisclosure.propTypes = {
    pageContext: PropTypes.shape({
        schema: PropTypes.object.isRequired,
    }),
    data: PropTypes.shape({
        wordpressPage: PropTypes.shape({
            title: PropTypes.string.isRequired,
            content: PropTypes.string.isRequired,
        })
    }),
};

export default AdvertisementDisclosure

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            content
            seo_data
            wordpress_id
            title
        }
    }
`;
